import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | Loud Humans" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>This page cannot be.</h1>
          <p>When you look for something that doesn't exist, you often find what you were looking for was of little importance. Think of this as an oportunty for mental freedom that you can apply to new ideas. Maybe try our <a href="/">homepage</a> where there is great music to browse?</p>
          <h4>If you need to get in touch about a show or something, hit us up!</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
